import Image from "next/image";

interface Props {
  handleClick: () => void;
  className?: string;
  header?: boolean;
  isHeroSection?: boolean;
}

export const SearchButton = ({
  handleClick,
  className,
  header,
  isHeroSection,
}: Props) => {
  return (
    <button
      onClick={handleClick}
      className={`${className} ${header && "!h-7 !w-7"} h-[50px] w-[50px] flex items-center justify-center bg-primaryB4 rounded-[5px]`}
    >
      <Image
        src="https://res.cloudinary.com/dz3tveb47/image/upload/v1728384100/Vector_5_mnc2hn.png"
        alt="search-button"
        width={500}
        height={500}
        className={`object-cover ${header && "!w-4 !h-4"} w-7 h-7`}
      />
    </button>
  );
};
